export const getMetamodel = (
  data: { Totale: Number }[],
  options = {
    elementId: 'analyticsView-total-sales-unpaid-year',
    /** format: 202011091700 (yyyyMMddhhmm) */
    processedDate: '',
    kpiName: 'ScadenziarioAttivoInsolutiAnno',
  }
) => {
  return {
    kpiName: options.kpiName,
    elementId: options.elementId,
    dataElaborazione: options.processedDate,
    disableAnimation: false,
    data: {
      metamodel: {
        name: 'scadenziario_attivo_insoluti_anno',
        title: 'alyante.std.contabilita_finanziaria.scadenziario_attivo_insoluti_anno.title',
        description:
          'alyante.std.contabilita_finanziaria.scadenziario_attivo_insoluti_anno.description',
        tooltip: 'alyante.std.contabilita_finanziaria.scadenziario_attivo_insoluti_anno.tooltip',
        type: 'graph',
        layout: {
          h: 5,
        },
        graph: {
          types: ['columns', 'map', 'bars'],
          default_type: 'columns',
          value_label_format: '#.0a €',
          xAxis: {
            title: '',
            label: 'Anno',
            label_position: 'outside',
          },
          series: [
            {
              id: 1,
              title: null,
              field: 'Totale',
              color: '#438d9d',
            },
          ],
        },
        order: {
          field: 'Anno',
          type: 'asc',
        },
        rules: null,
        cursor_behavior: 'panX',
      },
      values: {
        dataElaborazione: options.processedDate,
        data: data,
      },
    },
  };
};
