import './cash-flow.css';
import React, { useEffect, useState } from 'react';
import { getMetamodel } from '../../../metamodels/cash-flow-out';

export function CashFlowOut(props) {
  const { data } = props;

  const [isLoading, setIsLoading] = useState(true);

  const elementId = 'analyticsView-cash-flow-out';
  const kpiName = 'CashFlowEntrate';

  const widgetOptions = {
    embedFonts: false,
    showContainer: true,
    showFooter: false,
    showTitle: true,
  };

  const metamodelOptions = { elementId, kpiName, };

  useEffect(() => {
    if (!data) {
      setIsLoading(true);
    } else if (data.error) {
      setIsLoading(false);
      window.analyticsRender(metamodelOptions, widgetOptions);
    } else {
      setIsLoading(false);
      window.analyticsRender(getMetamodel(data, metamodelOptions), widgetOptions);
    }
  }, [data]);

  return <div class="cash-flow-out-widget">
    {isLoading ?
      <div>
      </div> : <>
        <div id={elementId} class="cash-flow-out-widget">
        </div>
      </>
    }
  </div>
}
