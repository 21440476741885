import React from 'react';
import texts from '../../utils/texts';

const dataErrorImg = require('../../assets/data-error.svg');

export function DataError(props) {
  const { height } = props;

  return <div className="data-error-container" style={{ height: height + 'px' }}>
    <div className="data-error-section">
      <img alt="" src={dataErrorImg} />
      <div className="data-error-text">
        {texts('NO_DATA')}
        <br />
        {texts('REFRESH_BROWSER')}
      </div>
    </div>
  </div>;
}
