import _ from 'lodash';
import texts from '../utils/texts';

export const aggregateData = (
  rawData: {
    day: Number,
    flow: 'Incoming' | 'Outgoing',
    last_update: String,
    month: Number,
    year: Number,
    value: Number,
    /** 'Customer payments', 'Supplier payments' */
    payment_type: String,
    owner_name: String,
    owner_id: String,
  }[]
): {
  base: {
    TotaleInsoluti: Number,
    TotaleScadenze: Number,
    PercentualeIncidenza: Number,
  }[],
  period: {
    Indice: Number, // 1, 2, 3, 4, 5
    Tipo: String, // 'oltre 120 gg', '90-120 gg', '60-90 gg', '30-60 gg', '0-30 gg'
    Totale: Number,
  }[],
  year: {
    Anno: Number,
    Totale: Number,
  }[],
} => {
  if (!rawData) return rawData;

  const today = new Date();

  // filter for current year and 'customer payments' payment type
  const customerPayments = _.filter(
    rawData.map((x) => {
      return {
        ...x,
        dueDate: new Date(x.year, x.month - 1, x.day),
      };
    }),
    (row) => row.payment_type && row.payment_type.toLowerCase() === 'customer payments'
  );

  const customerUnpaid = _.filter(customerPayments, (row) => row.dueDate < today);

  const currentYearUnpaidTotal = _.sumBy(
    _.filter(customerUnpaid, (y) => y.dueDate.getFullYear() === today.getFullYear()),
    (x) => x.value
  );
  const customerUnpaidTotal = _.sumBy(customerUnpaid, (x) => x.value);

  const base = [
    {
      TotaleInsoluti: currentYearUnpaidTotal,
      TotaleScadenze: _.sumBy(customerUnpaid, (x) => x.value),
      PercentualeIncidenza:
        customerUnpaidTotal === 0
          ? 0
          : Math.abs((currentYearUnpaidTotal / customerUnpaidTotal) * 100).toFixed(2),
    },
  ];

  const groupedByYear = _.groupBy(customerUnpaid, (x) => x.dueDate.getFullYear());

  const year = _.map(groupedByYear, (rows, year) => {
    if (year <= today.getFullYear()) {
      return {
        Anno: year,
        Totale: _.sumBy(rows, (x) => x.value),
      };
    }
  }).filter((element) => !!element);

  const paymentsWithExpiryDays = customerUnpaid.map((x) => {
    return { ...x, expiryDays: getDifferenceDays(x.dueDate, today) };
  });

  const bucket1 = paymentsWithExpiryDays.filter(getBinningCallback(120, Infinity));
  const bucket2 = paymentsWithExpiryDays.filter(getBinningCallback(90, 120));
  const bucket3 = paymentsWithExpiryDays.filter(getBinningCallback(60, 90));
  const bucket4 = paymentsWithExpiryDays.filter(getBinningCallback(30, 60));
  const bucket5 = paymentsWithExpiryDays.filter(getBinningCallback(0, 30));

  const period = [
    {
      Indice: 1,
      Tipo: texts('SALES_UNPAID_PERIOD_1'),
      Totale: _.sumBy(bucket1, (x) => x.value),
    },
    {
      Indice: 2,
      Tipo: texts('SALES_UNPAID_PERIOD_2'),
      Totale: _.sumBy(bucket2, (x) => x.value),
    },
    {
      Indice: 3,
      Tipo: texts('SALES_UNPAID_PERIOD_3'),
      Totale: _.sumBy(bucket3, (x) => x.value),
    },
    {
      Indice: 4,
      Tipo: texts('SALES_UNPAID_PERIOD_4'),
      Totale: _.sumBy(bucket4, (x) => x.value),
    },
    {
      Indice: 5,
      Tipo: texts('SALES_UNPAID_PERIOD_5'),
      Totale: _.sumBy(bucket5, (x) => x.value),
    },
  ];

  return {
    base,
    year,
    period,
  };
};

const getDifferenceDays = (a, b) => {
  const msecsInADay = 60 * 60 * 24 * 1000;
  return Math.floor((b.getTime() - a.getTime()) / msecsInADay);
};

const getBinningCallback = (start, end) => (element) =>
  element.expiryDays >= start && element.expiryDays < end;
