export const getMetamodel = (
  data: { Totale: Number }[],
  options = {
    elementId: 'analyticsView-total-sales-unpaid-period',
    /** format: 202011091700 (yyyyMMddhhmm) */
    processedDate: '',
    kpiName: 'ScadenziarioAttivoInsolutiPeriodi',
  }
) => {
  return {
    kpiName: options.kpiName,
    elementId: options.elementId,
    dataElaborazione: options.processedDate,
    disableAnimation: false,
    data: {
      metamodel: {
        name: 'scadenziario_attivo_insoluti_periodi',
        title: 'alyante.std.contabilita_finanziaria.scadenziario_attivo_insoluti_periodi.title',
        description:
          'alyante.std.contabilita_finanziaria.scadenziario_attivo_insoluti_periodi.description',
        tooltip: 'alyante.std.contabilita_finanziaria.scadenziario_attivo_insoluti_periodi.tooltip',
        type: 'graph',
        layout: {
          h: 5,
        },
        graph: {
          types: ['columns', 'bars'],
          default_type: 'columns',
          value_label_format: '#.0a €',
          xAxis: {
            title: '',
            label: 'Tipo',
          },
          series: [
            {
              id: 1,
              title: null,
              field: 'Totale',
              color_set: ['#1F4F72', '#3E8FCB', '#57C1E6', '#57C1E6', '#57C1E6'],
            },
          ],
        },
        order: {
          field: 'Indice',
          type: 'asc',
        },
        rules: null,
      },
      values: {
        dataElaborazione: options.processedDate,
        data: data,
      },
    },
  };
};
