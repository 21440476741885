import translations from './translations';
import configuration from '../store/shared-configuration';

export function getUserLanguage() {
  const userLang = configuration?.value?.language || navigator.language || navigator.userLanguage;
  return userLang;
}

export function getUserISOLanguage() {
  const userLang = getUserLanguage();
  if (userLang.length === 5 && !translations.fallbacks[userLang]) return userLang;
  else return translations.fallbacks[userLang];
}

// *** Add here other languages ***
const supportedLanguages = ['it-IT', 'es-ES', 'de-DE', 'en-GB'];

export function getUserISOLanguageForAnalytics() {
  let language = getUserISOLanguage();
  if (language === 'en-GL') {
    language = 'en-GB';
  } else if (!supportedLanguages.includes(language)) {
    // this rollback prevents the 'window.setGlobalDictionary' function from crashing and therefore giving problems to the GUI
    language = 'en-GB';
  }
  return language;
}

function getTranslation(textId) {
  const allLanguages = translations.texts[textId];

  if (!allLanguages) {
    return textId;
  }
  const iso = getUserISOLanguage();

  if (iso in allLanguages) {
    return allLanguages[iso];
  } else if (translations.fallbacks[iso]) {
    return allLanguages[translations.fallbacks[iso]];
  } else {
    return allLanguages['en-GL'];
  }
}

export default (textId, replacements) => {
  const text = getTranslation(textId);
  if (!replacements) {
    return text;
  }

  return text.replace(/\[[^\[\]]+\]/g, (match) => {
    const withoutBrackets = match.slice(1, -1);
    const replacement = replacements[withoutBrackets];
    return replacement == null ? match : replacement;
  });
};
