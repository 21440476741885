import { createSlice } from '@reduxjs/toolkit';
import configuration from './shared-configuration';
import { getInitialConfiguration } from '../api/auth';
import { logError } from '../api/errors';

export const slice = createSlice({
  name: 'configuration',
  initialState: {
    configuration: null,
  },
  reducers: {
    setConfiguration: (state, action) => {
      state.configuration = action.payload;
    },
  },
});

export function getConfiguration() {
  return async function getConfigurationThunk(dispatch, getState) {
    try {
      var res = await getInitialConfiguration();
      configuration.value = res;
      dispatch(slice.actions.setConfiguration(res));
    } catch (err) {
      logError(err);
      dispatch(null);
    }
  };
}

export const selectConfiguration = (state) => state.configuration.configuration;
export const { setConfiguration: initialize } = slice.actions;

export default slice.reducer;
