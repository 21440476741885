import { TimesIcon } from '@revisohq/react-components/icons';
import React, { useState, useEffect } from 'react';
import ShadowContainer from '../containers/shadow-container'
import sharedConfig from '../../store/shared-configuration';
import analyticsConfig from '../../analytics-integration/configuration.json';
import './activate-ts-analytics.css';
import texts from '../../utils/texts';
import { DotsLoader } from '../loader/loaders';
import { activateTsAnalytics } from '../../api/auth';

const analyticsImage = require('../../assets/undraw_all_the_data.svg');
const menuImage = require('../../assets/menu2.png');

export function ActivateTsAnalytics(props) {

  const { delay } = props;

  const inputStyle = {
    marginRight: '10px'
  };

  const inputFormStyle = {
    display: 'flex',
    flexDirection: 'column'
  };

  const inputContainerStyle = {
    display: 'flex',
    // alignItems: 'center',
    flexDirection: 'column'
  }

  const stateArray = {
    success: 'ACTIVATION_SUCCESS',
    upgrade: 'UPGRADE',
    error: 'ACTIVATION_ERROR',
    vatNumberNeeded: 'VAT_NUMBER_NOT_CORRECT',
    alreadyActivated: 'VAT_NUMBER_ALREADY_ACTIVATED',
    default: 'INIT',
    loading: 'LOADING'
  }

  const [showBanner, setShowBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState(stateArray.default);
  const [showIntegrateBtn, setShowIntegrateBtn] = useState(true);
  const [disableModalBtn, setDisableModalBtn] = useState(false);
  const [analyticsUsers, setAnalyticsUsers] = useState(0);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();

  const checkIntegrateBtn = () => {
    if (firstName && lastName) {
      setDisableModalBtn(false);
    } else {
      setDisableModalBtn(true);
    }
  }

  const integrate = () => {
    if (state === stateArray.default && !disableModalBtn) {
      setState(stateArray.loading);

      const analyticsConf = {
        ...sharedConfig.value.analytics,
        firstName,
        lastName
      };

      activateTsAnalytics(analyticsConf).then((res) => {
        if (res === 'unauthorized') {
          setState(stateArray.upgrade);
          setShowIntegrateBtn(false);
        } else if (res === 'vat number not found') {
          setState(stateArray.vatNumberNeeded);
          setShowIntegrateBtn(false);
        } else if (res === 'conflict') {
          setState(stateArray.alreadyActivated);
          setShowIntegrateBtn(false);
        } else if (res) {
          setState(stateArray.success);
          setShowIntegrateBtn(false);
        } else
          setState(stateArray.error);
      }).catch((err) => {
        setState(stateArray.error);
      })
    }
  }

  const open = () => {
    if (showIntegrateBtn) {
      setState(stateArray.default);
      setShowModal(true);
    }
  }

  const close = () => {
    if (state !== stateArray.loading)
      setShowModal(false);
    if (state === stateArray.success) {
      // TODO: show some other message ?
    }
  }

  const getRow = (plan) => {
    const kpisNumber = analyticsConfig.available_kpis[plan];
    let row: String;
    if (kpisNumber === 'ALL') row = texts('ALL_KPIS')
    else {
      const parsedKpis = parseInt(analyticsConfig.available_kpis[plan]);
      if (isNaN(parsedKpis) || parsedKpis === 0) {
        row = texts('NOT_AVAILABLE')
      } else {
        row = texts(parsedKpis > 1 ? 'KPIS' : 'KPI', { 'NUMBER': parsedKpis })
      }
    }
    row += analyticsConfig.share_users[plan] ?
      ` - ${texts('SHARE_WITH_USERS', { 'NUMBER': analyticsConfig.share_users[plan] })}` : '';
    return row;
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }

  const getModalTitle = (state) => {
    switch (state) {
      case stateArray.success:
        return texts('ACTIVATION_SUCCESS');
      default:
        return texts('INTEGRATE_ANALYTICS');
    }
  }

  const getModalContent = (state) => {
    switch (state) {
      case stateArray.loading:
        return <div className="modal-loading">
          <DotsLoader />
        </div>
      case stateArray.success:
        return <div className="modal-success activate-ts-analytics-text mb-0"
          style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
          <p style={{ margin: '0', fontSize: 'small' }}>
            {texts('ACTIVATION_SUCCESS_1')}{" "}{texts('ACTIVATION_SUCCESS_2')}
          </p>
          <img class="menu-img" alt="Menu" src={menuImage} />
        </div>
      case stateArray.upgrade:
        return <div className="modal-success activate-ts-analytics-text mb-0" style={{ flexDirection: 'column' }}>
          <p style={{ margin: '0', lineHeight: '20px !important' }}>{texts('UPGRADE_NEEDED_1')}</p>
          <a href="https://app.reviso.com/#!/settings/subscription/pricing-page"
            style={{ color: "#1D8CD3" }} target="_blank" rel="noopener noreferrer">
            {texts('UPGRADE_NEEDED_2')}
          </a>
        </div>
      case stateArray.vatNumberNeeded:
        return <div className="modal-error activate-ts-analytics-text mb-0">
          <p>{texts('VAT_NUMBER_NOT_CORRECT')}</p>
        </div>
      case stateArray.alreadyActivated:
        return <div className="modal-error activate-ts-analytics-text mb-0">
          <p>{texts('VAT_NUMBER_ALREADY_ACTIVATED')}</p>
        </div>
      case stateArray.error:
        return <div className="modal-error activate-ts-analytics-text mb-0">
          <p>{texts('ACTIVATION_ERROR')}</p>
        </div>
      default:
        return <p className="activate-ts-analytics-text modal-text mb-0">
          <div style={inputContainerStyle}>
            <div style={inputFormStyle}>
              <b style={{ lineHeight: '16px' }}>{texts('FIRST_NAME')} *</b>
              <input type="text" value={firstName} style={inputStyle}
                onChange={(val) => { setFirstName(val.target.value) }} />
            </div>
            <div style={inputFormStyle}>
              <b style={{ lineHeight: '16px', marginTop: '5px' }}>{texts('LAST_NAME')} *</b>
              <input type="text" value={lastName} style={inputStyle}
                onChange={(val) => { setLastName(val.target.value) }} />
            </div>
          </div>
          <b>{texts('OWNER')}:</b> {sharedConfig.value?.analytics.email ?? ''}<br />
          <b>{texts('COMPANY')}:</b> {sharedConfig.value?.analytics.companyName ?? ''}<br />
          <b>{texts('COMPANY_VAT')}:</b> {sharedConfig.value?.analytics.companyVatNumber ?? ''}<br />
          <b>{texts('CURRENT_PLAN')}:</b> {sharedConfig.value?.self.status.isTrial ?
            texts('TRIAL') : (
              sharedConfig.value?.self.subscriptionInfo.plan.name ?? ''
            )}<br />
          {/* <b>{texts('ANALTYTICS_ENABLED_USERS')}:</b> {analyticsUsers} */}
        </p>
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (sharedConfig.value?.analytics.featureFlag &&
        !sharedConfig.value?.analytics.isServiceEnabled)
        setShowBanner(true);
      if (sharedConfig.value?.analytics?.firstName)
        setFirstName(sharedConfig.value?.analytics?.firstName)
      if (sharedConfig.value?.analytics?.lastName)
        setLastName(sharedConfig.value?.analytics?.lastName)
    }, delay ?? 0)
    setAnalyticsUsers(1);
  }, [])

  useEffect(() => {
    checkIntegrateBtn();
  }, [firstName, lastName])

  return showBanner && <ShadowContainer>
    <div style={{ position: 'relative' }}>
      <div className="flex activate-ts-analytics">
        <div className="activate-ts-analytics-container">
          <div class="scrollable">
            <p className="activate-ts-analytics-title">
              {texts('WANT_MORE')}
            </p>
            <p className="activate-ts-analytics-text mb-0">
              {texts('INTEGRATE_DESC')}<br />
              {texts('INTEGRATE_DESC_2')}
            </p>
            <p className="activate-ts-analytics-text mb-0">
              <b>{texts('TRIAL')}:</b> {getRow('trial')}<br />
              <b>{texts('TIER_1')}:</b> {getRow('tier_1')}<br />
              <b>{texts('TIER_2')}:</b> {getRow('tier_2')}<br />
              <b>{texts('TIER_3')}:</b> {getRow('tier_3')}
            </p>
          </div>
          <div className="flex fit-content auto-margin">
            <div className={`activate-ts-analytics-btn btn-1 ${showIntegrateBtn ? '' : 'btn-disabled'}`}
              onClick={open}>
              {texts('INTEGRATE_ANALYTICS')}
            </div>
            <div className={`activate-ts-analytics-btn btn-2`}
              onClick={() => { openInNewTab(sharedConfig.value?.analyticsUserGuideUrl) }}>
              {texts('EXPAND')}
            </div>
          </div>
        </div>
        <div class="display-contents">
          <img class="activate-ts-analytics-img" alt="TS Analytics" src={analyticsImage} />
        </div>
      </div>
      {showModal && <div className="activate-ts-analytics-container activate-ts-analytics-modal">
        <div className="activate-ts-analytics-modal-close"
          onClick={close}>
          <TimesIcon />
        </div>
        <p className="activate-ts-analytics-title modal-title">
          {getModalTitle(state)}
        </p>
        <div className="modal-content">
          {getModalContent(state)}
        </div>
        {state !== stateArray.success && <div className="more-kpi-modal-btns">
          <div className={`activate-ts-analytics-btn btn-3 ${state !== stateArray.default ? "btn-disabled" : ""}`}
            onClick={() => { if (state === stateArray.default) close() }}>
            {texts('CANCEL')}
          </div>
          <div className={`activate-ts-analytics-btn btn-1 ${state !== stateArray.default || disableModalBtn ? "btn-disabled" : ""}`}
            onClick={integrate}>
            {texts('INTEGRATE')}
          </div>
        </div>}
      </div>}
    </div>
  </ShadowContainer>
}