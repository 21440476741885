import './cash-flow.css';
import React, { useEffect, useState } from 'react';
import { getUserISOLanguage } from '../../../utils/texts';
import { DotsLoader } from '../../loader/loaders';
import { getMetamodel } from '../../../metamodels/cash-flow';
import { Popup } from '../../additional-info/popup';

export function CashFlow(props) {
  const { data, lastUpdateTime, dictionary } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [showInfo, setShowInfo] = useState(false);

  const elementId = 'analyticsView-cash-flow';
  const kpiName = 'CashFlowPerMese';
  const language = getUserISOLanguage();

  const widgetOptions = {
    embedFonts: false,
    showContainer: true,
    showFooter: false,
    showTitle: true,
  };

  const metamodelOptions = { elementId, kpiName, };

  useEffect(() => {
    if (!data) {
      setIsLoading(true);
    } else if (data.error) {
      setIsLoading(false);
      window.analyticsRender(metamodelOptions, widgetOptions);
    } else {
      setIsLoading(false);
      window.analyticsRender(getMetamodel(data, metamodelOptions), widgetOptions)
        .then(() => {
          setTimeout(() => {
            setShowInfo(true);
          }, 2000)
        });
    }
  }, [data]);

  return <div class="cash-flow-widget">
    {isLoading ?
      <div class="cash-flow-widget-loader">
        <DotsLoader />
      </div> : <>
        <Popup
          show={showInfo}
          title={dictionary?.alyante.std.contabilita_finanziaria.cashflow_per_mese.title}
          text={dictionary?.alyante.std.contabilita_finanziaria.cashflow_per_mese.description}
          date={lastUpdateTime}
          locale={language}
        />
        <div id={elementId} class="cash-flow-widget">
        </div>
      </>
    }
  </div>
}
