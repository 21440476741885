export const getMetamodel = (
  data: { Totale: Number }[],
  options = {
    elementId: 'analyticsView-payments-due-dates',
    /** format: 202011091700 (yyyyMMddhhmm) */
    processedDate: '',
    kpiName: 'ScadenziarioAttivoClientiTotale',
    onExpand: null,
    onKpiDetail: null,
  }
) => {
  return {
    kpiName: options.kpiName,
    elementId: options.elementId,
    dataElaborazione: options.processedDate,
    onExpand: options.onExpand,
    onKpiDetail: options.onKpiDetail,
    disableAnimation: false,
    data: {
      metamodel: {
        name: 'scadenziario_attivo_clienti_totale',
        title:
          'alyante.std.contabilita_finanziaria.scadenziario_attivo_clienti_totale.title',
        description:
          'alyante.std.contabilita_finanziaria.scadenziario_attivo_clienti_totale.description',
        tooltip:
          'alyante.std.contabilita_finanziaria.scadenziario_attivo_clienti_totale.tooltip',
        type: 'simple',
        layout: {
          h: 3,
        },
        items: [
          {
            id: 1,
            type: 'amount_value',
            field: 'Totale',
            label: 'global.current_year',
          },
        ],
        rules: null,
      },
      values: {
        dataElaborazione: options.processedDate,
        data: data,
      },
    },
  };
};
