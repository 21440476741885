export const getMetamodel = (
  data: {
    Provenienza: String,
    TotaleUscite: Number,
    PercentualeUscite: Number,
  }[],
  options = {
    elementId: 'analyticsView-cash-flow-out',
    kpiName: 'CashFlowUscite',
  }
) => {
  return {
    kpiName: options.kpiName,
    elementId: options.elementId,
    disableAnimation: false,
    data: {
      metamodel: {
        name: 'cashflow_uscite',
        title: 'alyante.std.contabilita_finanziaria.cashflow_uscite.title',
        description:
          'alyante.std.contabilita_finanziaria.cashflow_uscite.description',
        tooltip: 'alyante.std.contabilita_finanziaria.cashflow_uscite.tooltip',
        type: 'graph',
        layout: {
          h: 7,
        },
        graph: {
          types: ['semiring', 'bars', 'columns'],
          default_type: 'semiring',
          xAxis: {
            title: '',
            label: 'Provenienza',
            label_position: null,
          },
          center_label: {
            field: 'TotaleUscite',
            type: 'sum',
            label:
              'alyante.std.contabilita_finanziaria.cashflow_uscite.center_label',
            format: 'currency',
          },
          series: [
            {
              id: 1,
              title: null,
              field: 'TotaleUscite',
              color_set: ['#d5b6e1', '#a247b9', '#0083c3'],
            },
          ],
        },
        order: {
          field: 'Provenienza',
          type: 'asc',
        },
        rules: null,
      },
      values: {
        data: data,
      },
    },
  };
};
