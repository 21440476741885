import React from 'react';
import { Link } from 'react-router-dom';
import '../app.css';
import * as routeNames from '../routes';
import texts from '../utils/texts';

export function HomePage() {

  const routes = [
    routeNames.revenues,
    routeNames.costs,
    routeNames.salesOrders,
    routeNames.purchaseOrders,
    routeNames.paymentsDueDates,
    routeNames.totalSalesUnpaid,
    routeNames.cashFlow,
  ]

  return <div className="widget-home">
    <p style={{ textAlign: 'center' }}>
      {texts('REVISO_DASHBOARD')}<br />
      {texts('CHOOSE_YOUR_WIDGET') + ': '}<br />
      {routes.map(r => {
        return <>
          <Link to={'/' + r}>
            {r}
          </Link><br />
        </>
      })}
      <Link to={'/multiple/payments-due-dates+revenues+total-sales-unpaid+costs+sales-orders+purchase-orders+cash-flow'}>
        {texts('ALL_WIDGETS')}
      </Link>
    </p>
  </div>
}
