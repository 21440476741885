import './total-sales-unpaid.css';
import React, { useEffect, useState } from 'react';
import { getUserISOLanguage } from '../../../utils/texts';
import { DotsLoader } from '../../loader/loaders';
import { getMetamodel } from '../../../metamodels/total-sales-unpaid';
import { AdditionalInfo } from '../../additional-info/additional-info';
import { Popup } from '../../additional-info/popup';

export function TotalSalesUnpaid(props) {
  const { data, lastUpdateTime, dictionary } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [showInfo, setShowInfo] = useState(false);

  const elementId = 'analyticsView-total-sales-unpaid';
  const kpiName = 'ScadenziarioAttivoInsolutiTotale';
  const language = getUserISOLanguage();

  const widgetOptions = {
    embedFonts: false,
    showContainer: true,
    showFooter: false,
    showTitle: true,
  };

  const metamodelOptions = { elementId, kpiName, };

  useEffect(() => {
    if (!data) {
      setIsLoading(true);
    } else if (data.error) {
      setIsLoading(false);
      window.analyticsRender(metamodelOptions, widgetOptions);
    } else {
      setIsLoading(false);
      window.analyticsRender(getMetamodel(data, { ...metamodelOptions, processedDate: lastUpdateTime }), widgetOptions)
        .then(() => {
          setTimeout(() => {
            setShowInfo(true);
          }, 2000)
        });
    }
  }, [data]);

  return <div class="total-sales-unpaid-widget">
    {isLoading ?
      <div class="total-sales-unpaid-widget-loader">
        <DotsLoader />
      </div> : <>
        <Popup
          show={showInfo}
          title={dictionary?.alyante.std.contabilita_finanziaria.scadenziario_attivo_insoluti_totale.title}
          text={dictionary?.alyante.std.contabilita_finanziaria.scadenziario_attivo_insoluti_totale.description}
          date={lastUpdateTime}
          locale={language} />
        <AdditionalInfo show={showInfo} onTotal={true} />
        <div id={elementId} class="total-sales-unpaid-widget">
        </div>
      </>
    }
  </div>
}
