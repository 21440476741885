export const getMetamodel = (
  data: {
    Provenienza: String,
    TotaleEntrate: Number,
    PercentualeEntrate: Number,
  }[],
  options = {
    elementId: 'analyticsView-cash-flow-in',
    kpiName: 'CashFlowEntrate',
  }
) => {
  return {
    kpiName: options.kpiName,
    elementId: options.elementId,
    disableAnimation: false,
    data: {
      metamodel: {
        name: 'cashflow_entrate',
        title: 'alyante.std.contabilita_finanziaria.cashflow_entrate.title',
        description:
          'alyante.std.contabilita_finanziaria.cashflow_entrate.description',
        tooltip: 'alyante.std.contabilita_finanziaria.cashflow_entrate.tooltip',
        type: 'graph',
        layout: {
          h: 7,
        },
        graph: {
          types: ['semiring', 'bars', 'columns'],
          default_type: 'semiring',
          xAxis: {
            title: '',
            label: 'Provenienza',
            label_position: null,
          },
          center_label: {
            field: 'TotaleEntrate',
            type: 'sum',
            label:
              'alyante.std.contabilita_finanziaria.cashflow_entrate.center_label',
            format: 'currency',
          },
          series: [
            {
              id: 1,
              title: null,
              field: 'TotaleEntrate',
              color_set: ['#9bda9f', '#00aa00', '#9bda9f'],
            },
          ],
        },
        order: {
          field: 'Provenienza',
          type: 'asc',
        },
        rules: null,
      },
      values: {
        data: data,
      },
    },
  };
};
