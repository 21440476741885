export const getMetamodel = (
  data: {
    Mese: Number,
    Fatturato: Number,
    DaFatturare: Number,
    OrdiniDaEvadere: Number,
  }[],
  options = {
    elementId: 'analyticsView-costs-ddts-purchase-orders',
    /** format: 202011091700 (yyyyMMddhhmm) */
    processedDate: '',
    kpiName: 'AcquistatoDdtOrdiniDaEvadere',
    onExpand: null,
    onKpiDetail: null,
  }
) => {
  return {
    kpiName: options.kpiName,
    elementId: options.elementId,
    dataElaborazione: options.processedDate,
    onExpand: options.onExpand,
    onKpiDetail: options.onKpiDetail,
    disableAnimation: false,
    data: {
      metamodel: {
        name: 'acquistato_ddt_ordinidaevadere',
        title: 'alyante.std.ciclo_passivo.acquistato_ddt_ordinidaevadere.title',
        type: 'graph',
        layout: { h: 7 },
        graph: {
          types: ['stackedbars', 'stackedcolumns'],
          default_type: 'stackedcolumns',
          value_label_format: '#.0a €',
          xAxis: {
            title: '',
            label: 'Mese',
            label_type: 'ShortMonth',
            label_position: 'outside',
          },
          series: [
            {
              id: 1,
              title: {
                type: 'label',
                value:
                  'alyante.std.ciclo_passivo.acquistato_ddt_ordinidaevadere.fatturato',
              },
              field: 'Fatturato',
              color: '#483464',
            },
            {
              id: 2,
              title: {
                type: 'label',
                value:
                  'alyante.std.ciclo_passivo.acquistato_ddt_ordinidaevadere.da_fatturare',
              },
              field: 'DaFatturare',
              color: '#a247b9',
            },
            {
              id: 3,
              title: {
                type: 'label',
                value:
                  'alyante.std.ciclo_passivo.acquistato_ddt_ordinidaevadere.ordini_da_evadere',
              },
              field: 'OrdiniDaEvadere',
              color: '#d5b6e1',
            },
          ],
        },
        order: {
          field: 'Mese',
          type: 'asc',
        },
        rules: null,
      },
      values: {
        dataElaborazione: options.processedDate,
        data: data,
      },
    },
  };
};
