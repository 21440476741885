
import { Popup as RevisoReactPopup } from '@revisohq/react-components';
import { InfoCircleIcon } from '@revisohq/react-components';
import React, { useState } from 'react';
import { getLocaleDateTimeString } from '../../utils/common';
import texts from '../../utils/texts';
import parse from 'html-react-parser';

export const Popup = (props) => {
  const { show, title, text, date, locale, classes } = props;

  const parsedClasses = typeof classes === 'string' ? classes : '';

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const popupStyle = {
    position: 'fixed',
    top: '20%',
    minWidth: "320px",
    maxWidth: "700px",
    textAlign: 'left'
  };

  return show && <>
    <div title={texts('SHOW_INFO')} className={"popup-info " + parsedClasses}
      onClick={() => { setIsPopupOpen(true) }}>
      <InfoCircleIcon />
    </div>
    {isPopupOpen && <RevisoReactPopup modal
      style={popupStyle}
      onClose={() => { setIsPopupOpen(false) }}
    >
      <div className="popup-info-content">
        <h3 className="popup-info-title">
          {title ?? ''}
        </h3>
        <div className="popup-info-scroll">
          {date && <i>
            {texts('LAST_UPDATE_TIME', { 'DATE': getLocaleDateTimeString(date, locale) })}
          </i>}
          {parse(text ?? '')}
        </div>
      </div>
    </RevisoReactPopup>
    }
  </>;
}