import _ from 'lodash';
import texts from '../utils/texts';

export const aggregateData = (
  rawData: {
    day: Number,
    flow: 'Incoming' | 'Outgoing',
    last_update: String,
    month: Number,
    year: Number,
    value: Number,
    /** 'Customer payments', 'Supplier payments' */
    payment_type: String,
    owner_name: String,
    owner_id: String,
  }[]
): {
  base: {
    Totale: Number,
  }[],
  status: {
    Totale: Number,
    Tipo: String,
    DescrizioneTipo: String,
  }[],
  top10Customers: {
    CodiceCliente: String,
    RagioneSocialeCliente: String,
    Totale: Number,
    // IncidenzaSulTotale: Number,
  }[],
} => {
  if (!rawData) return rawData;

  const today = new Date();

  // filter for current year and 'customer payments' payment_type
  const parsedFilteredData = _.filter(
    rawData.map((x) => {
      return {
        ...x,
        dueDate: new Date(x.year, x.month - 1, x.day),
      };
    }),
    (row) =>
      row.payment_type &&
      row.payment_type.toLowerCase() === 'customer payments' &&
      row.year === today.getFullYear()
  );

  const base = {
    Totale: _.sumBy(parsedFilteredData, (x) => x.value),
  };

  const status = [
    {
      Tipo: 'ascadere',
      DescrizioneTipo: texts('TO_EXPIRE'),
      Totale: _.sumBy(
        _.filter(parsedFilteredData, (val) => val.dueDate >= today),
        (x) => x.value
      ),
    },
    {
      Tipo: 'scaduto',
      DescrizioneTipo: texts('EXPIRED'),
      Totale: _.sumBy(
        _.filter(parsedFilteredData, (val) => val.dueDate < today),
        (x) => x.value
      ),
    },
  ];

  const groupedByOwner = _.groupBy(parsedFilteredData, (val) => val.owner_id);

  const maxCharsAllowed = 12;

  // collect the sum of all the payments per each customer and sort by this sum in descending order
  const orderedCustomers = _.orderBy(
    _.map(groupedByOwner, (value, key) => {
      return {
        RagioneSocialeCliente: value.length === 0 ? '' : value[0].owner_name,
        Totale: _.sumBy(value, (x) => x.value),
      };
    }),
    (x) => x.Totale,
    'desc'
  );

  // select the top 10 and slice the name if it's too long
  // we're adding the `${index + 1} - ` first because if the sliced name match for more than one customer, their values will be displayed as one
  const top10Customers = orderedCustomers.slice(0, 10).map((value, index) => {
    const trimmedName =
      value.RagioneSocialeCliente.length > maxCharsAllowed
        ? value.RagioneSocialeCliente.slice(0, maxCharsAllowed + 1) + '...'
        : value.RagioneSocialeCliente;
    return {
      ...value,
      RagioneSocialeCliente: `${index + 1} - ${trimmedName}`,
    };
  });

  return {
    base: [base],
    status,
    top10Customers,
  };
};
