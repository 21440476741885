export const getMetamodel = (
  data: {
    FatturatoAnnoInCorso: Number,
    PercentualeIncrementoFatturato: Number,
  }[],
  options = {
    elementId: 'analyticsView-revenue',
    /** format: 202011091700 (yyyyMMddhhmm) */
    processedDate: '',
    kpiName: 'FatturatoTotale',
    onExpand: null,
    onKpiDetail: null,
  }
) => {
  return {
    kpiName: options.kpiName,
    elementId: options.elementId,
    dataElaborazione: options.processedDate,
    onExpand: options.onExpand,
    onKpiDetail: options.onKpiDetail,
    disableAnimation: false,
    data: {
      metamodel: {
        name: 'fatturato',
        title: 'alyante.std.ciclo_attivo.fatturato_totale.title',
        description: 'alyante.std.ciclo_attivo.fatturato_totale.description',
        tooltip: 'alyante.std.ciclo_attivo.fatturato_totale.tooltip',
        type: 'simple',
        layout: {
          h: 1,
        },
        items: [
          {
            id: 1,
            type: 'amount_value',
            field: 'FatturatoAnnoInCorso',
            label: 'global.current_year',
          },
          {
            id: 2,
            type: 'percent_value',
            field: 'PercentualeIncrementoFatturato',
            label: 'global.compared_to',
          },
        ],
        rules: [
          {
            id: 1,
            type: 'field_value_check',
            threshold: 0,
            field: 'PercentualeIncrementoFatturato',
            value: 0,
          },
        ],
      },
      values: {
        dataElaborazione: options.processedDate,
        data: data,
      },
    },
  };
};
