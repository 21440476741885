import _ from 'lodash';

export const aggregateData = (rawData: {
  accruedCosts: {
    value: Number,
    year: Number,
    month: Number,
    day: Number,
  }[],
  costs: {
    value: Number,
    year: Number,
    month: Number,
    day: Number,
  }[],
  purchaseOrderedToBeInvoiced: {
    value: Number,
    year: Number,
    month: Number,
    day: Number,
  }[],
}): {
  Mese: Number,
  /** sum of Costs KPI for the month */
  Fatturato: Number,
  /** sum of Accrued costs KPI for the month */
  DaFatturare: Number,
  /** sum of Purchase ordered to be invoiced KPI for the month */
  OrdiniDaEvadere: Number,
}[] => {
  if (!rawData) return [];

  const parsedData = {
    costs: parseDate(rawData.costs ?? []),
    purchaseOrderedToBeInvoiced: parseDate(rawData.purchaseOrderedToBeInvoiced ?? []),
    accruedCosts: parseDate(rawData.accruedCosts ?? []),
  };

  // group kpis per month
  const costsByMonth = _.groupBy(parsedData.costs, (x) => x.month);
  const accruedCostsByMonth = _.groupBy(parsedData.accruedCosts, (x) => x.month);
  const purchaseOrderedByMonth = _.groupBy(parsedData.purchaseOrderedToBeInvoiced, (x) => x.month);

  // fill array with all the months and the sum of the kpis per each month
  const gatheredData = _.range(1, 13).map((month) => {
    return {
      Mese: month,
      Fatturato: _.sumBy(costsByMonth[month] ?? [], (x) => x.value),
      DaFatturare: _.sumBy(accruedCostsByMonth[month] ?? [], (x) => x.value),
      OrdiniDaEvadere: _.sumBy(purchaseOrderedByMonth[month] ?? [], (x) => x.value),
    };
  });

  return gatheredData;
};

const parseDate = (array) => {
  if (array.length === 0) return [];
  else
    return array.map((x) => {
      return {
        ...x,
        date: new Date(x.year, x.month - 1, x.day),
      };
    });
};
