import token from '../store/shared-token';

export const getHeader = (method) => {
  switch (method) {
    case 'POST-TEXT':
      return {
        'Content-Type': 'text/plain',
        'x-reviso-newrevisodashboard-grant-token': token.value,
      };
    case 'POST':
      return {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-reviso-newrevisodashboard-grant-token': token.value,
      };
    default:
      return {
        'x-reviso-newrevisodashboard-grant-token': token.value,
      };
  }
};
