export const getMetamodel = (
  data: { Totale: Number }[],
  options = {
    elementId: 'analyticsView-total-sales-unpaid',
    /** format: 202011091700 (yyyyMMddhhmm) */
    processedDate: '',
    kpiName: 'ScadenziarioAttivoInsolutiTotale',
  }
) => {
  return {
    kpiName: options.kpiName,
    elementId: options.elementId,
    dataElaborazione: options.processedDate,
    disableAnimation: false,
    data: {
      metamodel: {
        name: 'scadenziario_attivo_insoluti_totale',
        title: 'alyante.std.contabilita_finanziaria.scadenziario_attivo_insoluti_totale.title',
        description:
          'alyante.std.contabilita_finanziaria.scadenziario_attivo_insoluti_totale.description',
        tooltip: 'alyante.std.contabilita_finanziaria.scadenziario_attivo_insoluti_totale.tooltip',
        type: 'simple',
        layout: {
          h: 3,
        },
        items: [
          {
            id: 1,
            type: 'amount_value',
            field: 'TotaleInsoluti',
            label: 'global.current_year',
          },
          {
            id: 2,
            type: 'percent_value',
            field: 'PercentualeIncidenza',
            title:
              'alyante.std.contabilita_finanziaria.scadenziario_attivo_insoluti_totale.percentuale_incidenza',
            label:
              'alyante.std.contabilita_finanziaria.scadenziario_attivo_insoluti_clienti_totale.sub_on_total',
          },
          {
            id: 3,
            type: 'amount_value',
            field: 'TotaleScadenze',
            title:
              'alyante.std.contabilita_finanziaria.scadenziario_attivo_insoluti_totale.totale_scadenze',
          },
        ],
        rules: null,
      },
      values: {
        dataElaborazione: options.processedDate,
        data: data,
      },
    },
  };
};
