export function DeepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function getFormattedDate(date: String, format = 'it-IT') {
  if (!date) return '';
  switch (format) {
    case 'it-IT':
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, 10);
      return day + '/' + month + '/' + year;
    default:
      return date.substring(0, 10);
  }
}

export function parseJsonDate(date: String): Date {
  if (!date || date.includes('0001-01-01')) return null;

  const year = date.substring(0, 4),
    month = date.substring(5, 7),
    day = date.substring(8, 10),
    hours = date.substring(11, 13),
    minutes = date.substring(14, 16);

  return new Date(Date.UTC(year, month - 1, day, hours, minutes));
}

export function getJSONStringDate(date: Date) {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}`;
}

export function getLocaleDateTimeString(date: Date, locale: String) {
  const dateOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    hourOptions = {
      hour: '2-digit',
      minute: '2-digit',
    };
  return `${date.toLocaleDateString(locale, dateOptions)}, ${date.toLocaleTimeString(
    locale,
    hourOptions
  )}`;
}

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
