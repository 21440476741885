import React from 'react';
import { Route, Switch, HashRouter, Redirect } from 'react-router-dom';
import { RevenuesDdtsSalesOrders } from './components/kpis/revenues-ddts-sales-orders/revenues-ddts-sales-orders';
import { CostsDdtsPurchaseOrders } from './components/kpis/costs-ddts-purchase-orders/costs-ddts-purchase-orders';
import { HomePage } from './pages/home';
import { RevenueKpiPage } from './pages/revenue-kpi-page';
import { CostsKpiPage } from './pages/costs-kpi-page';
import { PaymentsDueDatesKpiPage } from './pages/payments-due-dates-kpi-page';
import { MultiWidgetPage } from './pages/multi-widget-page';
import { CashFlowKpiPage } from './pages/cash-flow-kpi-page';
import { TotalSalesUnpaidKpiPage } from './pages/total-sales-unpaid-kpi-page';
import { ActivateTsAnalyticsPage } from './pages/activate-ts-analytics-page';

function Routes() {
  return (
    <React.Fragment>
      <HashRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/more" component={ActivateTsAnalyticsPage} />
          <Route exact path={'/' + revenues} component={RevenueKpiPage} />
          <Route exact path={'/' + costs} component={CostsKpiPage} />
          <Route exact path={'/' + paymentsDueDates} component={PaymentsDueDatesKpiPage} />
          <Route exact path={'/' + salesOrders} component={RevenuesDdtsSalesOrders} />
          <Route exact path={'/' + purchaseOrders} component={CostsDdtsPurchaseOrders} />
          <Route exact path={'/' + totalSalesUnpaid} component={TotalSalesUnpaidKpiPage} />
          <Route exact path={'/' + cashFlow} component={CashFlowKpiPage} />
          <Route exact path={'/multiple/:command'} component={MultiWidgetPage} />
          <Redirect from="*" to="/" />
        </Switch>
      </HashRouter>
    </React.Fragment>
  );
}

export default Routes;

export const revenues = 'revenues';
export const costs = 'costs';
export const paymentsDueDates = 'payments-due-dates';
export const salesOrders = 'sales-orders';
export const purchaseOrders = 'purchase-orders';
export const totalSalesUnpaid = 'total-sales-unpaid';
export const cashFlow = 'cash-flow';
