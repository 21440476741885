import axios from 'axios';

export async function getDictionary(url, language) {
  try {
    const result = await axios.get(url + language);
    return result.data;
  } catch (error) {
    console.error(error);
    // Fallback: get english dictionary
    const result = await axios.get(url + 'en-GB');
    return result.data;
  }
}
