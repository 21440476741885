export const getMetamodel = (
  data: {
    /** Format yyyyMM */
    AnnoMese: Number,
    DescrizioneMese: String,
    Entrate: Number,
    Uscite: Number,
    Saldo: Number,
  }[],
  options = {
    elementId: 'analyticsView-cash-flow',
    kpiName: 'CashFlowPerMese',
  }
) => {
  return {
    kpiName: options.kpiName,
    elementId: options.elementId,
    disableAnimation: false,
    data: {
      metamodel: {
        name: 'cashflow_per_mese',
        title: 'alyante.std.contabilita_finanziaria.cashflow_per_mese.title',
        type: 'graph',
        layout: {
          h: 8,
        },
        graph: {
          types: ['columns_with_line'],
          default_type: 'columns_with_line',
          value_label_format: ['#.0a €', '#.0a €'],
          xAxis: {
            title: '',
            label: 'DescrizioneMese',
            label_position: 'outside',
            label_rotation: -45,
          },
          series: [
            {
              id: 1,
              type: 'columns',
              title: {
                type: 'label',
                value:
                  'alyante.std.contabilita_finanziaria.cashflow_per_mese.entrate',
              },
              field: 'Entrate',
              color: '#9bda9f',
            },
            {
              id: 2,
              type: 'columns',
              title: {
                type: 'label',
                value:
                  'alyante.std.contabilita_finanziaria.cashflow_per_mese.uscite',
              },
              field: 'Uscite',
              color: '#d5b6e1',
            },
            {
              id: 3,
              type: 'line',
              title: {
                type: 'label',
                value:
                  'alyante.std.contabilita_finanziaria.cashflow_per_mese.saldo',
              },
              field: 'Saldo',
              color: '#0e5822',
            },
          ],
        },
        order: {
          field: 'AnnoMese',
          type: 'asc',
        },
        rules: null,
      },
      values: {
        data: data,
      },
    },
  };
};
