import axios from 'axios';
import { getHeader } from './headers';

export async function getInitialConfiguration(): {
  url: String,
  token: String,
  language: String,
  dictionaryUrl: String,
} {
  try {
    const result = await axios.get(`api/auth`, {
      headers: getHeader('GET'),
    });
    return result.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function activateTsAnalytics(body) {
  try {
    const result = await axios
      .post(`api/auth/activate-ts-analytics`, body, {
        headers: getHeader('POST'),
      })
      .catch((error) => {
        if (error.response.status === 400 && error.response.data === 'vat number not found')
          return { data: error.response.data };
        if (error.response.status === 409) return { data: 'conflict' };
        if (error.response.status === 401) return { data: 'unauthorized' };

        throw error;
      });
    return result.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function getTokenAsync() {
  try {
    const hrefParts = window.location.href.split('?');
    let eat = null;

    if (hrefParts.length === 2) {
      eat = new URLSearchParams(hrefParts[1]).get('embeddedAppToken');
    }

    var res = await fetch(`api/grant/${eat}`, {
      method: 'GET',
    });

    return await res.json();
  } catch (error) {
    return console.log(error);
  }
}
