import axios from 'axios';
import * as _kpiHelper from '../query/query-helpers';
import * as routeNames from '../routes';

export async function getRevenues(info) {
  return new Promise((resolve, reject) => {
    try {
      const current_year = new Date().getFullYear();
      const query = _kpiHelper.getQuery(routeNames.revenues);
      callGraphQL(routeNames.revenues, info, query, {
        current_year,
        previous_year: current_year - 1,
      }).then((queryResult) => {
        if (!queryResult || !queryResult.data) {
          reject(queryResult);
        } else {
          const data = queryResult.data[_kpiHelper.revenues];
          resolve(data);
        }
      });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}

export async function getCosts(info) {
  return new Promise((resolve, reject) => {
    try {
      const current_year = new Date().getFullYear();
      const query = _kpiHelper.getQuery(routeNames.costs);
      callGraphQL(routeNames.costs, info, query, {
        current_year,
        previous_year: current_year - 1,
      }).then((queryResult) => {
        if (!queryResult || !queryResult.data) {
          reject(queryResult);
        } else {
          const data = queryResult.data[_kpiHelper.purchases] ?? [];
          resolve(data);
        }
      });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}

export async function getCashFlow(info) {
  return new Promise((resolve, reject) => {
    try {
      const query = _kpiHelper.getQuery(routeNames.cashFlow);
      callGraphQL(routeNames.cashFlow, info, query, {
        year: new Date().getFullYear(),
      }).then((queryResult) => {
        if (!queryResult || !queryResult.data) {
          reject(queryResult);
        } else {
          const cashFlow = queryResult.data[_kpiHelper.cashFlow];
          const dueDates = queryResult.data[_kpiHelper.dueDates];
          resolve({ cashFlow, dueDates });
        }
      });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}

export async function getSalesOrders(info) {
  return new Promise((resolve, reject) => {
    try {
      const query = _kpiHelper.getQuery(routeNames.salesOrders);
      callGraphQL(routeNames.salesOrders, info, query, {
        year: new Date().getFullYear(),
      }).then((queryResult) => {
        if (!queryResult || !queryResult.data) {
          reject(queryResult);
        } else {
          const salesOrders = queryResult.data[_kpiHelper.salesOrderedToBeInvoiced];
          const revenues = queryResult.data[_kpiHelper.revenues];
          const accruedRevenues = queryResult.data[_kpiHelper.accruedRevenue];
          resolve({
            revenues,
            salesOrders,
            accruedRevenues,
          });
        }
      });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}

export async function getPurchaseOrders(info) {
  return new Promise((resolve, reject) => {
    try {
      const query = _kpiHelper.getQuery(routeNames.purchaseOrders);
      callGraphQL(routeNames.purchaseOrders, info, query, {
        year: new Date().getFullYear(),
      }).then((queryResult) => {
        if (!queryResult || !queryResult.data) {
          reject(queryResult);
        } else {
          const purchaseOrders = queryResult.data[_kpiHelper.purchaseOrders];
          const costs = queryResult.data[_kpiHelper.purchases];
          const accruedCosts = queryResult.data[_kpiHelper.accruedCosts];
          resolve({
            costs,
            purchaseOrders,
            accruedCosts,
          });
        }
      });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}

export async function getTotalSaleUnpaid(info) {
  return new Promise((resolve, reject) => {
    try {
      const query = _kpiHelper.getQuery(routeNames.totalSalesUnpaid);
      callGraphQL(routeNames.totalSalesUnpaid, info, query).then((queryResult) => {
        if (!queryResult || !queryResult.data) {
          reject(queryResult);
        } else {
          const data = queryResult.data[_kpiHelper.dueDates] ?? [];
          resolve(data);
        }
      });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}

export async function getPaymentsDueDates(info) {
  return new Promise((resolve, reject) => {
    try {
      const query = _kpiHelper.getQuery(routeNames.paymentsDueDates);
      callGraphQL(routeNames.paymentsDueDates, info, query, {
        year: new Date().getFullYear(),
      }).then((queryResult) => {
        if (!queryResult || !queryResult.data) {
          reject(queryResult);
        } else {
          const data = queryResult.data[_kpiHelper.dueDates] ?? [];
          resolve(data);
        }
      });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}

async function callGraphQL(kpiName, info, query, variables) {
  try {
    const result = await axios.post(
      info.url,
      {
        query,
        variables,
        operationName: kpiName.replaceAll('-', '_') + '_query',
      },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + info.token,
        },
      }
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}
