import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { RevenueKpiPage } from './revenue-kpi-page';
import { CostsKpiPage } from './costs-kpi-page';
import { CashFlowKpiPage } from './cash-flow-kpi-page';
import * as routeNames from '../routes';
import { PaymentsDueDatesKpiPage } from './payments-due-dates-kpi-page';
import { RevenuesDdtsSalesOrders } from '../components/kpis/revenues-ddts-sales-orders/revenues-ddts-sales-orders';
import { CostsDdtsPurchaseOrders } from '../components/kpis/costs-ddts-purchase-orders/costs-ddts-purchase-orders';
import { TotalSalesUnpaidKpiPage } from './total-sales-unpaid-kpi-page';
import configuration from '../store/shared-configuration';
import { getDictionary } from '../api/dictionary';
import { getUserISOLanguageForAnalytics } from '../utils/texts';
import { ActivateTsAnalytics } from '../components/activate-ts-analytics/activate-ts-analytics';

const WIDGETS_LOAD_DELAY = 600;

export function MultiWidgetPage() {
  const { command } = useParams();

  const [widgetList, setWidgetList] = useState([]);
  const [dictionary, setDictionary] = useState(null);
  const [kpiInfo, setKpiInfo] = useState(null);
  const [isLanguageConfigured, setIsLanguageConfigured] = useState(false);

  useEffect(() => {
    const run = async () => {
      const language = getUserISOLanguageForAnalytics();
      if (!isLanguageConfigured) {
        try {
          window.setGlobalDictionary(language);
        } catch (err) {
          console.error('setGlobalDictionary failed, falling back to english', err);
          window.setGlobalDictionary('en-GB');
        }
        setIsLanguageConfigured(true);
      }

      if (!kpiInfo) {
        const info = configuration.value;
        setKpiInfo({ url: info.url, token: info.token });
      }

      if (!dictionary && configuration.value?.dictionaryUrl) {
        const dict = await getDictionary(configuration.value.dictionaryUrl, language);
        setDictionary(dict);
      }
      const commandsArray = (command ?? '').split('+');
      if (widgetList !== commandsArray) setWidgetList(commandsArray);
    };
    run();
  }, [command, dictionary]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
      {widgetList.map((command, index, array) => {
        switch (command) {
          case routeNames.revenues:
            return (
              <div style={{ width: '50%' }}>
                <RevenueKpiPage
                  dictionary={dictionary}
                  delay={index * WIDGETS_LOAD_DELAY}
                  isLanguageConfigured={isLanguageConfigured}
                  kpiInfo={kpiInfo}
                />
              </div>
            );
          case routeNames.costs:
            return (
              <div style={{ width: '50%' }}>
                <CostsKpiPage
                  dictionary={dictionary}
                  delay={index * WIDGETS_LOAD_DELAY}
                  isLanguageConfigured={isLanguageConfigured}
                  kpiInfo={kpiInfo}
                />
              </div>
            );
          case routeNames.salesOrders:
            return (
              <div style={{ width: '50%' }}>
                <RevenuesDdtsSalesOrders
                  dictionary={dictionary}
                  delay={index * WIDGETS_LOAD_DELAY}
                  isLanguageConfigured={isLanguageConfigured}
                  kpiInfo={kpiInfo}
                />
              </div>
            );
          case routeNames.purchaseOrders:
            return (
              <div style={{ width: '50%' }}>
                <CostsDdtsPurchaseOrders
                  dictionary={dictionary}
                  delay={index * WIDGETS_LOAD_DELAY}
                  isLanguageConfigured={isLanguageConfigured}
                  kpiInfo={kpiInfo}
                />
              </div>
            );
          case routeNames.cashFlow:
            const fullWidth = index === array.length - 1 && array.length % 2 !== 0;
            return (
              <div style={{ width: fullWidth ? '100%' : '50%' }}>
                <CashFlowKpiPage
                  dictionary={dictionary}
                  delay={index * WIDGETS_LOAD_DELAY}
                  isLanguageConfigured={isLanguageConfigured}
                  kpiInfo={kpiInfo}
                />
              </div>
            );
          case routeNames.paymentsDueDates:
            return (
              <div style={{ width: '50%' }}>
                <PaymentsDueDatesKpiPage
                  dictionary={dictionary}
                  delay={index * WIDGETS_LOAD_DELAY}
                  isLanguageConfigured={isLanguageConfigured}
                  kpiInfo={kpiInfo}
                />
              </div>
            );
          case routeNames.totalSalesUnpaid:
            return (
              <div style={{ width: '50%' }}>
                <TotalSalesUnpaidKpiPage
                  dictionary={dictionary}
                  delay={index * WIDGETS_LOAD_DELAY}
                  isLanguageConfigured={isLanguageConfigured}
                  kpiInfo={kpiInfo}
                />
              </div>
            );
          default:
            return null;
        }
      })}
      <div style={{ width: '100%' }}>
        <ActivateTsAnalytics delay={1000} />
      </div>
    </div>
  );
}
