import axios from 'axios';
import { getHeader } from './headers';

export async function logError(error) {
  console.error(error);
  if (error) {
    try {
      await axios.post(`api/log/error`, JSON.stringify(error), {
        headers: getHeader('POST-TEXT'),
      });
    } catch (err) {
      // do nothing
    }
  }
}
