
import { AngleDownIcon, AngleUpIcon } from '@revisohq/react-components/icons';
import React from 'react';
import texts from '../../utils/texts';

export const ExpandWidget = (props) => {
  const { isOpen, setIsOpen } = props;

  return <div className="expand-widget">
    <div className="expand-widget-button" onClick={() => { setIsOpen(!isOpen) }}>
      {isOpen ? <>
        {texts('CLOSE')}&nbsp;<AngleUpIcon />
      </> : <>
        {texts('EXPAND')}&nbsp;<AngleDownIcon />
      </>}
    </div>
  </div>
}