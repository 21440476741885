export const getMetamodel = (
  data: { Totale: Number }[],
  options = {
    elementId: 'analyticsView-payments-due-dates-status',
    /** format: 202011091700 (yyyyMMddhhmm) */
    processedDate: '',
    kpiName: 'ScadenziarioAttivoClientiScadutoScadere',
    onExpand: null,
    onKpiDetail: null,
  }
) => {
  return {
    kpiName: options.kpiName,
    elementId: options.elementId,
    dataElaborazione: options.processedDate,
    onExpand: options.onExpand,
    onKpiDetail: options.onKpiDetail,
    disableAnimation: false,
    data: {
      metamodel: {
        name: 'scadenziario_attivo_clienti_scaduto_ascadere',
        title:
          'alyante.std.contabilita_finanziaria.scadenziario_attivo_clienti_scaduto_ascadere.title',
        description:
          'alyante.std.contabilita_finanziaria.scadenziario_attivo_clienti_scaduto_ascadere.description',
        tooltip:
          'alyante.std.contabilita_finanziaria.scadenziario_attivo_clienti_scaduto_ascadere.tooltip',
        type: 'graph',
        graph: {
          types: ['pie', 'bars', 'columns'],
          default_type: 'pie',
          xAxis: {
            title: '',
            label: 'DescrizioneTipo',
            label_type: null,
            label_position: 'outside',
          },
          series: [
            {
              id: 1,
              title: null,
              field: 'Totale',
              color_set: ['#00aa00', '#124150'],
            },
          ],
        },
        rules: null,
      },
      values: {
        dataElaborazione: options.processedDate,
        data: data,
      },
    },
  };
};
