import * as routeNames from '../routes';

export const getQuery = (kpiName: String) => {
  switch (kpiName) {
    case routeNames.revenues:
      // OK
      return `
        query ${kpiName}_query ($current_year: smallint!, $previous_year: smallint!) {
          ${revenues}(where: {year: {_in: [$current_year, $previous_year]}}) {
            year
            value
            month
            day
            last_update
          }
        }
      `;
    case routeNames.costs:
      return `
        query ${kpiName}_query ($current_year: smallint!, $previous_year: smallint!) {
          ${purchases}(where: {year: {_in: [$current_year, $previous_year]}}) {
            year
            value
            month
            day
            last_update
          }
        }
      `;
    case routeNames.cashFlow:
      // OK
      const parsedName = kpiName.replaceAll('-', '_');
      return `
        query ${parsedName}_query ($year: smallint!) {
          ${cashFlow}(where: {year: {_eq: $year}}) {
            bank_account_name
            day
            bank_account_id
            flow
            month
            payment_type
            value
            year
            last_update
          }

          ${dueDates}(where: {year: {_eq: $year}}) {
            year
            value
            payment_type
            owner_name
            owner_id
            month
            flow
            day
            last_update
          }
        }
      `;
    case routeNames.salesOrders:
      return `
        query ${kpiName.replaceAll('-', '_')}_query ($year: smallint!) {
          ${salesOrderedToBeInvoiced}(where: {year: {_eq: $year}}) {
            day
            month
            value
            year
            last_update
          }

          ${revenues}(where: {year: {_eq: $year}}) {
            year
            value
            month
            day
            last_update
          }

          ${accruedRevenue}(where: {year: {_eq: $year}}) {
            year
            value
            month
            day
            last_update
          }
        }
      `;
    case routeNames.purchaseOrders:
      // TODO complete (ddts)
      return `
        query ${kpiName.replaceAll('-', '_')}_query ($year: smallint!) {
          ${purchaseOrders}(where: {year: {_eq: $year}}) {
            day
            month
            value
            year
            last_update
          }

          ${purchases}(where: {year: {_eq: $year}}) {
            year
            value
            month
            day
            last_update
          }
        }
      `;
    case routeNames.paymentsDueDates:
      return `
        query ${kpiName.replaceAll('-', '_')}_query ($year: smallint!) {
          ${dueDates}(where: {year: {_eq: $year}}) {
            year
            value
            payment_type
            owner_name
            owner_id
            month
            flow
            day
            last_update
          }
        }
      `;
    case routeNames.totalSalesUnpaid:
      return `
        query ${kpiName.replaceAll('-', '_')}_query {
          ${dueDates} {
            year
            value
            payment_type
            owner_name
            owner_id
            month
            flow
            day
            last_update
          }
        }
      `;
    default:
      return '';
  }
};

export const revenues = 'kpi_revenue_by_day';
export const purchases = 'kpi_purchases';
export const salesOrders = 'kpi_sales_orders_by_day';
export const salesOrderedToBeInvoiced = 'kpi_sales_to_be_invoiced_by_day';
export const purchaseOrders = 'kpi_purchase_orders_by_day'; // TODO temp
export const purchaseOrderedToBeInvoiced = 'kpi_purchase_orders_by_day'; // TODO temp
export const cashFlow = 'kpi_cash_flow';
export const dueDates = 'kpi_due_dates';
export const accruedRevenue = 'kpi_accrued_revenue_total';
export const accruedCosts = 'kpi_accrued_cost'; // TODO temporary (it's not used for now)
