import texts from '../utils/texts';

export const getMetamodel = (
  data: {
    Mese: Number,
    AnnoInCorso: Number,
    AnnoPrecedente: Number,
    FatturatoAnnoPrecedente: Number,
    FatturatoAnnoPrecedenteOltrePeriodo: Number,
    FatturatoAnnoInCorso: Number,
  }[],
  options = {
    elementId: 'analyticsView-costs-monthly',
    /** format: 202011091700 (yyyyMMddhhmm) */
    processedDate: '',
    kpiName: 'AcquistatoMensileAnnoInCorsoAnnoPrecedente',
    onExpand: null,
    onKpiDetail: null,
  }
) => {
  return {
    kpiName: options.kpiName,
    elementId: options.elementId,
    dataElaborazione: options.processedDate,
    onExpand: options.onExpand,
    onKpiDetail: options.onKpiDetail,
    disableAnimation: false,
    data: {
      metamodel: {
        name: 'acquistato_mensile',
        title: 'alyante.std.ciclo_passivo.acquistato_mensile.title',
        description: 'alyante.std.ciclo_passivo.acquistato_mensile.description',
        tooltip: 'alyante.std.ciclo_passivo.acquistato_mensile.tooltip',
        type: 'graph',
        layout: {
          h: 5,
        },
        graph: {
          types: ['bars', 'columns'],
          default_type: 'columns',
          value_label_format: '#.0a €',
          xAxis: {
            title: '',
            label: 'Mese',
            label_type: 'ShortMonth',
            label_position: 'outside',
          },
          series: [
            {
              id: 1,
              title: {
                type: 'field',
                value: 'AnnoInCorso',
              },
              field: 'FatturatoAnnoInCorso',
              color: '#a247b9',
              stacked: false,
              radius: 5,
            },
            {
              id: 2,
              title: {
                type: 'field',
                value: 'AnnoPrecedente',
              },
              field: 'FatturatoAnnoPrecedente',
              color: '#483464',
              stacked: false,
              radius: 5,
            },
            {
              id: 3,
              title: {
                type: 'formula',
                value: texts('REMAINING_MONTHS'),
              },
              field: 'FatturatoAnnoPrecedenteOltrePeriodo',
              color: '#d5b6e1',
              stacked: true,
              radius: 5,
            },
          ],
        },
        order: {
          field: 'Mese',
          type: 'asc',
        },
        rules: null,
      },
      values: {
        dataElaborazione: options.processedDate,
        data: data,
      },
    },
  };
};
