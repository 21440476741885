import './revenues-ddts-sales-orders.css';
import React, { useEffect, useState } from 'react';
import { getUserISOLanguage, getUserISOLanguageForAnalytics } from '../../../utils/texts';
import * as _api from '../../../api/data';
import { DotsLoader } from '../../loader/loaders';
import { getMetamodel } from '../../../metamodels/revenues-ddts-sales-orders';
import { aggregateData } from '../../../data-processing/revenues-ddts-sales-orders';
import { Popup } from '../../additional-info/popup';
import { DataError } from '../../errors/data-error';
import ShadowContainer from '../../containers/shadow-container';
import { getDictionary } from '../../../api/dictionary';
import { parseJsonDate, timeout } from '../../../utils/common';
import configuration from '../../../store/shared-configuration';
import { logError } from '../../../api/errors';

export function RevenuesDdtsSalesOrders(props) {
  const { delay, dictionary, isLanguageConfigured, kpiInfo } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [showInfo, setShowInfo] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState();
  const [showDataError, setShowDataError] = useState(false);
  const [popupResources, setPopupResources] = useState({});

  const language = getUserISOLanguage();
  const kpiName = 'FatturatoDaFatturareOrdiniDaEvadere';
  const elementId = 'analyticsView-revenues-ddts-sales-orders';

  const widgetOptions = {
    embedFonts: false,
    showContainer: true,
    showFooter: false,
    showTitle: true,
  };

  const metamodelOptions = {
    elementId,
    kpiName,
  };

  const retrieveData = (info) => {
    _api.getSalesOrders(info).then((data) => {
      if (!data) {
        throw new Error('couldn\'t retrieve data from server');
      }
      if (data?.revenues?.length > 0) {
        const parsed = parseJsonDate(data.revenues[0].last_update)
        setLastUpdateTime(parsed);
      } else if (data?.salesOrders?.length > 0) {
        const parsed = parseJsonDate(data.salesOrders[0].last_update)
        setLastUpdateTime(parsed);
      }
      setIsLoading(false);
      const processedData = aggregateData(data);
      window.analyticsRender(getMetamodel(processedData, metamodelOptions), widgetOptions).then(() => {
        setTimeout(() => {
          setShowInfo(true);
        }, 2000)
      });
    }).catch((err) => {
      logError(err);
      setIsLoading(false);
      setShowDataError(true);
    });
  }

  useEffect(() => {
    const run = async () => {
      // Set language and dictionary if are not provided in props
      if (!isLanguageConfigured) {
        const language = getUserISOLanguageForAnalytics();
        try {
          window.setGlobalDictionary(language);
        } catch (err) {
          console.error('setGlobalDictionary failed', err);
        }
      }
      if (!dictionary && configuration.value?.dictionaryUrl) {
        const dict = await getDictionary(configuration.value.dictionaryUrl, language)
        setPopupResources({
          title: dict?.alyante.std.ciclo_attivo.fatturato_dafatturare_ordinidaevadere.title,
          text: dict?.alyante.std.ciclo_attivo.fatturato_dafatturare_ordinidaevadere.description
        })
      } else {
        setPopupResources({
          title: dictionary?.alyante.std.ciclo_attivo.fatturato_dafatturare_ordinidaevadere.title,
          text: dictionary?.alyante.std.ciclo_attivo.fatturato_dafatturare_ordinidaevadere.description
        })
      }
      await timeout(delay ?? 0);
      let info = kpiInfo;
      if (!kpiInfo && configuration.value) {
        info = {
          url: configuration.value.url,
          token: configuration.value.token
        };
      }
      retrieveData(info);
    }
    run();
  }, []);

  return showDataError ?
    <ShadowContainer>
      <DataError height={351} />
    </ShadowContainer> :
    <div class="widget">
      {isLoading ?
        <ShadowContainer>
          <div class="widget-loader">
            <DotsLoader />
          </div>
        </ShadowContainer> : <>
          <Popup
            show={showInfo}
            title={popupResources.title}
            text={popupResources.text}
            classes="right-30"
            date={lastUpdateTime}
            locale={language} />
          <div id={elementId} class="widget">
          </div>
        </>
      }
    </div>
}
