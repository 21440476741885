export const getMetamodel = (
  data: {
    Anno: Number,
    TotaleFatturato: Number,
  }[],
  options = {
    elementId: 'analyticsView-costs-yearly',
    /** format: 202011091700 (yyyyMMddhhmm) */
    processedDate: '',
    kpiName: 'AcquistatoAnnoInCorsoAnnoPrecedente',
    onExpand: null,
    onKpiDetail: null,
  }
) => {
  return {
    kpiName: options.kpiName,
    elementId: options.elementId,
    dataElaborazione: options.processedDate,
    onExpand: options.onExpand,
    onKpiDetail: options.onKpiDetail,
    disableAnimation: false,
    data: {
      metamodel: {
        name: 'acquistato_annuo',
        title: 'alyante.std.ciclo_passivo.acquistato_ac_ap.title',
        description: 'alyante.std.ciclo_passivo.acquistato_ac_ap.description',
        tooltip: 'alyante.std.ciclo_passivo.acquistato_ac_ap.tooltip',
        type: 'graph',
        layout: {
          h: 5,
        },
        graph: {
          types: ['bars', 'columns'],
          default_type: 'columns',
          value_label_format: '#.0a €',
          xAxis: {
            title: '',
            label: 'Anno',
            label_position: 'outside',
          },
          series: [
            {
              id: 1,
              title: null,
              field: 'TotaleFatturato',
              color_set: ['#483464', '#a247b9'],
            },
          ],
        },
        order: {
          field: 'Anno',
          type: 'asc',
        },
        rules: null,
      },
      values: {
        dataElaborazione: options.processedDate,
        data: data,
      },
    },
  };
};
