import _ from 'lodash';
import texts from '../utils/texts';

export const aggregateData = (
  cashFlow: {
    bank_account_name: String,
    day: Number,
    bank_account_id: Number,
    flow: 'Incoming' | 'Outgoing',
    month: Number,
    payment_type: String,
    value: Number,
    year: Number,
  }[],
  dueDates: {
    day: Number,
    flow: 'Incoming' | 'Outgoing',
    last_update: String,
    month: Number,
    year: Number,
    value: Number,
    payment_type: String,
    owner_name: String,
    owner_id: String,
  }[]
): {
  currentYear: Number,
  previousYear: Number,
  base: {
    /** Format yyyyMM (`${year}${month.toString().padStart(2, '0')}`) */
    AnnoMese: Number,
    DescrizioneMese: String,
    Entrate: Number,
    Uscite: Number,
    Saldo: Number,
  }[],
  in: {
    Provenienza: String,
    TotaleEntrate: Number,
    // PercentualeEntrate: Number,
  }[],
  out: {
    Provenienza: String,
    TotaleUscite: Number,
    // PercentualeUscite: Number,
  }[],
} => {
  if (!cashFlow && !dueDates) return cashFlow;

  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;

  const currentData = _.filter([...cashFlow, ...dueDates], (x) => x.year === currentYear);

  const currentYearByMonth = _.groupBy(currentData, (x) => x.month);

  let base = _.map(currentYearByMonth, (rows, month) => {
    if (rows.length === 0) return {};

    const incoming = Math.abs(
      _.sumBy(
        _.filter(rows, (x) => x.flow === 'Incoming'),
        (x) => x.value
      )
    );
    const outgoing = Math.abs(
      _.sumBy(
        _.filter(rows, (x) => x.flow === 'Outgoing'),
        (x) => x.value
      )
    );

    const previousMonths = _.filter(currentYearByMonth, (x) => {
      return x[0]?.month <= month;
    }).flat();

    const cumulativeIncoming = Math.abs(
      _.sumBy(
        _.filter(previousMonths, (x) => x.flow === 'Incoming'),
        (x) => x.value
      )
    );
    const cumulativeOutgoing = Math.abs(
      _.sumBy(
        _.filter(previousMonths, (x) => x.flow === 'Outgoing'),
        (x) => x.value
      )
    );

    return {
      AnnoMese: `${rows[0].year}${rows[0].month.toString().padStart(2, '0')}`,
      DescrizioneMese: getMonthDescription(rows[0].year, rows[0].month),
      Entrate: incoming,
      Uscite: -outgoing,
      Saldo: cumulativeIncoming - cumulativeOutgoing,
    };
  });

  if (base.length === 0) {
    base.push(getEmptyCashFlow());
  } else {
    base = _.orderBy(base, (x) => x.AnnoMese);
  }

  const currentYearInByBankAccount = _.groupBy(
    _.filter(currentData, (y) => y.flow === 'Incoming'),
    (x) => x.bank_account_id
  );

  const inMovements = _.map(currentYearInByBankAccount, (rows, bankId) => {
    return {
      Provenienza: rows[0].bank_account_name,
      TotaleEntrate: _.sumBy(rows, (x) => x.value),
    };
  });

  const currentYearOutByBankAccount = _.groupBy(
    _.filter(currentData, (y) => y.flow === 'Outgoing'),
    (x) => x.bank_account_id
  );

  const outMovements = _.map(currentYearOutByBankAccount, (rows, bankId) => {
    return {
      Provenienza: rows[0].bank_account_name,
      TotaleUscite: Math.abs(_.sumBy(rows, (x) => x.value)),
    };
  });

  const emptyMovement = [{ Provenienza: '', TotaleEntrate: 0 }];

  return {
    currentYear,
    previousYear,
    base,
    in: inMovements.length === 0 ? emptyMovement : inMovements,
    out: outMovements.length === 0 ? emptyMovement : outMovements,
  };
};

const getMonthDescription = (year, month) => {
  return texts('SHORT_MONTH_' + month);
};

const getEmptyCashFlow = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return {
    AnnoMese: `${year}${month.toString().padStart(2, '0')}`,
    DescrizioneMese: getMonthDescription(year, month),
    Entrate: 0,
    Uscite: 0,
    Saldo: 0,
  };
};
