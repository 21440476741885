import _ from 'lodash';

export const aggregateData = (rawData: {
  accruedRevenues: {
    value: Number,
    year: Number,
    month: Number,
    day: Number,
  }[],
  revenues: {
    value: Number,
    year: Number,
    month: Number,
    day: Number,
  }[],
  salesOrders: {
    value: Number,
    year: Number,
    month: Number,
    day: Number,
  }[],
}): {
  Mese: Number,
  /** sum of Revenues KPI for the month */
  Fatturato: Number,
  /** sum of Accrued revenues KPI for the month */
  DaFatturare: Number,
  /** sum of Sales ordered to be invoiced KPI for the month */
  OrdiniDaEvadere: Number,
}[] => {
  if (!rawData) return [];

  const parsedData = {
    revenues: parseDate(rawData.revenues ?? []),
    salesOrders: parseDate(rawData.salesOrders ?? []),
    accruedRevenues: parseDate(rawData.accruedRevenues ?? []),
  };

  // group kpis per month
  const revenuesByMonth = _.groupBy(parsedData.revenues, (x) => x.month);
  const accruedRevenuesByMonth = _.groupBy(parsedData.accruedRevenues, (x) => x.month);
  const salesOrderedByMonth = _.groupBy(parsedData.salesOrders, (x) => x.month);

  // fill array with all the months and the sum of the kpis per each month
  const gatheredData = _.range(1, 13).map((month) => {
    return {
      Mese: month,
      Fatturato: _.sumBy(revenuesByMonth[month] ?? [], (x) => x.value),
      DaFatturare: _.sumBy(accruedRevenuesByMonth[month] ?? [], (x) => x.value),
      OrdiniDaEvadere: _.sumBy(salesOrderedByMonth[month] ?? [], (x) => x.value),
    };
  });

  return gatheredData;
};

const parseDate = (array) => {
  if (array.length === 0) return [];
  else
    return array.map((x) => {
      return {
        ...x,
        date: (x.year, x.month - 1, x.day),
      };
    });
};
