
import React from 'react';
import texts from '../../utils/texts';

export const AdditionalInfo = (props) => {
  const { show, previousYear, onTotal } = props;

  return (show && <>
    <div className="subtitle current-year">
      {texts('CURRENT_YEAR')}
    </div>
    {previousYear ?
      <div className="subtitle previous-year-comparison">
        {`${texts('COMPARED_TO', { 'YEAR': previousYear })}`}
      </div>
      :
      (onTotal &&
        <div className="subtitle previous-year-comparison">
          {`${texts('ON_TOTAL')}`}
        </div>)
    }
  </>
  )
}