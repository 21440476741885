import Routes from './routes';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import '@revisohq/react-components/css/activity-indicator.css';
import { selectToken, getToken } from './store/agreement-token';
import { DotsLoader } from './components/loader/loaders';
import './app.css';
import { configure, ActivityIndicator } from '@revisohq/react-components';
import { format } from '@revisohq/js-libraries';
import texts from './utils/texts';
import { getConfiguration, selectConfiguration } from './store/configuration';

const formatter = new format.Date('yyy-mm-dd');
const amountFormatter = new format.Amount({
  groupingSeparator: '.',
  decimalSeparator: ',',
  minimumDecimals: 2,
  maximumDecimals: 2,
});

configure({
  amountInput: {
    format: amountFormatter.format,
    parse: amountFormatter.parse,
    roundValue: (x) => Number(x.toFixed(2)),
    characterOnNumpadDecimal: ',',
    allowCalculation: false,
  },
  datePicker: {
    format: formatter.format,
    parse: formatter.toJSON,
    l10n: {
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      days: ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'],
      openPaneButtonText: 'Select date',
      nextMonthButtonText: 'Next',
      previousMonthButtonText: 'Previous',
    },
  },
  dropdown: {
    noSearchResultsText: 'No matching results',
  },
  popup: {
    // `window` is the global window instance
    rootWindow: window,
  },
  listButtons: {
    stickyAreaButtonActiveText: 'Unlock button area',
    stickyAreaButtonInactiveText: 'Lock button area',
    columnCountActiveText: texts('SHOW_ACCOUNT_CODES'),
    columnCountInactiveText: texts('HIDE_ACCOUNT_CODES'),
  },
  paginationView: {
    getTextForCurrentPage: (data) => {
      if (data == null) {
        return <ActivityIndicator type="dots" />;
      }
      const { fromIndex, toIndex, totalItems } = data;
      if (totalItems === 0) {
        return 'No items';
      }
      return `Showing ${fromIndex}-${toIndex} of ${totalItems} items`;
    },
    firstPageButtonTitle: 'First page',
    lastPageButtonTitle: 'Last page',
    nextPageButtonTitle: 'Next page',
    previousPageButtonTitle: 'Previous page',
  },
  searchInput: {
    buttonText: 'Search',
  },
  // The tagsWidget has optional settings not included here
  tagsWidget: {
    duplicateEntryWarning: 'Tag already added',
    maxLengthWarning: 'Tags cannot be longer than [MAXLENGTH] characters.',
  },
});

function App() {
  const token = useSelector(selectToken);
  const configuration = useSelector(selectConfiguration);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) {
      dispatch(getToken()).then(() => {
        if (!configuration) {
          dispatch(getConfiguration());
        }
      });
    }
  }, [token]);

  return token && configuration ? (
    <Routes />
  ) : (
    <div className="loading-section">
      <DotsLoader />
    </div>
  );
}

export default App;
