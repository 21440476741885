export const getMetamodel = (
  data: { Totale: Number }[],
  options = {
    elementId: 'analyticsView-top-10-customers',
    /** format: 202011091700 (yyyyMMddhhmm) */
    processedDate: '',
    kpiName: 'ScadenziarioAttivoClientiTop10Scaduto',
    onExpand: null,
    onKpiDetail: null,
  }
) => {
  return {
    kpiName: options.kpiName,
    elementId: options.elementId,
    dataElaborazione: options.processedDate,
    onExpand: options.onExpand,
    onKpiDetail: options.onKpiDetail,
    disableAnimation: false,
    data: {
      metamodel: {
        name: 'scadenziario_attivo_clienti_top10_scaduto',
        title:
          'alyante.std.contabilita_finanziaria.scadenziario_attivo_clienti_top10_scaduto.title',
        description:
          'alyante.std.contabilita_finanziaria.scadenziario_attivo_clienti_top10_scaduto.description',
        tooltip:
          'alyante.std.contabilita_finanziaria.scadenziario_attivo_clienti_top10_scaduto.tooltip',
        type: 'graph',
        layout: {
          h: 7,
        },
        graph: {
          types: ['bars', 'columns'],
          default_type: 'bars',
          value_label_format: '#.0a €',
          xAxis: {
            title: '',
            label: 'RagioneSocialeCliente',
            truncate: true,
          },
          series: [
            {
              id: 1,
              title: null,
              field: 'Totale',
              color: '#00aa00',
            },
          ],
        },
        order: {
          field: 'Totale',
          type: 'desc',
        },
        rules: null,
      },
      values: {
        dataElaborazione: options.processedDate,
        data: data,
      },
    },
  };
};
