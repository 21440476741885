export const getMetamodel = (
  data: {
    mese: Number,
    fatturato: Number,
    daFatturare: Number,
    ordiniDaEvadere: Number,
  }[],
  options = {
    elementId: 'analyticsView-revenues-ddts-sales-orders',
    /** format: 202011091700 */
    processedDate: '',
    kpiName: 'FatturatoDaFatturareOrdiniDaEvadere',
    onExpand: null,
    onKpiDetail: null,
  }
) => {
  return {
    kpiName: options.kpiName,
    elementId: options.elementId,
    dataElaborazione: options.processedDate,
    onExpand: options.onExpand,
    onKpiDetail: options.onKpiDetail,
    disableAnimation: false,
    data: {
      metamodel: {
        name: 'fatturato_dafatturare_ordinidaevadere',
        title: 'alyante.std.ciclo_attivo.fatturato_dafatturare_ordinidaevadere.title',
        description: 'alyante.std.ciclo_attivo.fatturato_dafatturare_ordinidaevadere.description',
        tooltip: 'alyante.std.ciclo_attivo.fatturato_dafatturare_ordinidaevadere.tooltip',
        type: 'graph',
        layout: {
          h: 7,
        },
        graph: {
          types: ['stackedbars', 'stackedcolumns'],
          default_type: 'stackedcolumns',
          value_label_format: '#.0a €',
          xAxis: {
            title: '',
            label: 'Mese',
            label_type: 'ShortMonth',
            label_position: 'outside',
          },
          series: [
            {
              id: 1,
              title: {
                type: 'label',
                value: 'alyante.std.ciclo_attivo.fatturato_dafatturare_ordinidaevadere.fatturato',
              },
              field: 'Fatturato',
              color: '#0e5822',
            },
            {
              id: 2,
              title: {
                type: 'label',
                value:
                  'alyante.std.ciclo_attivo.fatturato_dafatturare_ordinidaevadere.da_fatturare',
              },
              field: 'DaFatturare',
              color: '#00aa00',
            },
            {
              id: 3,
              title: {
                type: 'label',
                value:
                  'alyante.std.ciclo_attivo.fatturato_dafatturare_ordinidaevadere.ordini_da_evadere',
              },
              field: 'OrdiniDaEvadere',
              color: '#9bda9f',
            },
          ],
        },
        order: {
          field: 'Mese',
          type: 'asc',
        },
        rules: null,
      },
      values: {
        dataElaborazione: options.processedDate,
        data: data,
      },
    },
  };
};
