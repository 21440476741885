import React, { useState, useEffect } from 'react';
import { Costs } from '../components/kpis/costs/costs';
import { CostsMonthly } from '../components/kpis/costs/costs-monthly';
import { CostsYearly } from '../components/kpis/costs/costs-yearly';
import * as _api from '../api/data';
import { aggregateData } from '../data-processing/revenues-costs';
import { ExpandWidget } from '../components/expand-widget/expand-widget';
import { DataError } from '../components/errors/data-error';
import { getUserISOLanguageForAnalytics } from '../utils/texts';
import { parseJsonDate } from '../utils/common';
import { getDictionary } from '../api/dictionary';
import configuration from '../store/shared-configuration';
import { logError } from '../api/errors';

export function CostsKpiPage(props) {
  const { delay, dictionary, isLanguageConfigured, kpiInfo } = props;

  const [data, setData] = useState();
  const [lastUpdateTime, setLastUpdateTime] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [showExpandBtn, setShowExpandBtn] = useState(false);
  const [showDataError, setShowDataError] = useState(false);
  const [dict, setDict] = useState();

  const retrieveData = (info) => {
    _api.getCosts(info).then((data) => {
      if (!data) {
        throw new Error('couldn\'t retrieve data from server');
      }
      if (data.length > 0) {
        const parsed = parseJsonDate(data[0].last_update)
        setLastUpdateTime(parsed);
      }
      const processedData = aggregateData(data);
      setData(processedData);
      setTimeout(() => {
        setShowExpandBtn(true);
      }, 1000);
    }).catch((err) => {
      logError(err);
      setShowDataError(true);
      setData({
        base: { error: true },
        monthly: { error: true },
        yearly: { error: true },
      });
    });
  }

  useEffect(() => {
    setDict(dictionary);
    if (!isLanguageConfigured) {
      const language = getUserISOLanguageForAnalytics();
      if (!dictionary) {
        getDictionary(configuration.value.dictionaryUrl, language).then((res) => {
          setDict(res);
        });
      }
      try {
        window.setGlobalDictionary(language);
      } catch (err) {
        console.error('setGlobalDictionary failed', err);
      }
    }
    setTimeout(() => {
      let info = kpiInfo;
      if (!kpiInfo && configuration.value) {
        info = {
          url: configuration.value.url,
          token: configuration.value.token
        };
      }
      retrieveData(info);
    }, delay ?? 0)
  }, []);

  return <>
    <div style={{ margin: '10px 0' }}>
      <div style={{ margin: '0 10px' }} className="container-shadow">
        {showDataError ?
          <DataError height={216} />
          : <>
            <Costs dictionary={dict} data={data?.base} previousYear={data?.previousYear} lastUpdateTime={lastUpdateTime} />
            {showExpandBtn &&
              <ExpandWidget isOpen={isOpen} setIsOpen={setIsOpen} />
            }
          </>
        }
      </div>
      {isOpen && <>
        <CostsYearly data={data?.yearly} />
        <CostsMonthly data={data?.monthly} />
      </>
      }
    </div>
  </>
}