import _ from 'lodash';

export const aggregateData = (
  rawData: {
    value: Number,
    year: Number,
    month: Number,
  }[]
): {
  currentYear: Number,
  previousYear: Number,
  base: {
    FatturatoAnnoInCorso: Number,
    PercentualeIncrementoFatturato: Number,
  }[],
  yearly: {
    Anno: Number,
    TotaleFatturato: Number,
  }[],
  monthly: {
    Mese: Number,
    AnnoInCorso: Number,
    AnnoPrecedente: Number,
    FatturatoAnnoPrecedente: Number,
    FatturatoAnnoPrecedenteOltrePeriodo: Number,
    FatturatoAnnoInCorso: Number,
  }[],
} => {
  if (!rawData) return rawData;

  let groupedByYear = _.groupBy(rawData, (x) => x.year);
  let years = _.keys(groupedByYear).map((year) => parseInt(year));

  filterYears(years, groupedByYear);

  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;

  let base = {};

  const yearly = _.map(groupedByYear, (value, key) => {
    const parsed = {
      Anno: parseInt(key),
      TotaleFatturato: _.sumBy(value, (x) => x.value),
    };
    if (parsed.Anno === currentYear) {
      base.FatturatoAnnoInCorso = parsed.TotaleFatturato;
    }
    return parsed;
  });

  const currentYearRevenue = _.find(yearly, (x) => x.Anno === currentYear)?.TotaleFatturato ?? 0;
  const previousYearRevenue = _.find(yearly, (x) => x.Anno === previousYear)?.TotaleFatturato ?? 0;

  if (previousYearRevenue === 0 && currentYearRevenue === 0)
    base.PercentualeIncrementoFatturato = 0;
  else if (previousYearRevenue === 0) base.PercentualeIncrementoFatturato = 100;
  else {
    const percentage = ((currentYearRevenue - previousYearRevenue) / previousYearRevenue) * 100;
    base.PercentualeIncrementoFatturato = percentage.toFixed(2);
  }

  const currentMonth = new Date().getMonth() + 1;
  const groupedByMonth = _.groupBy(rawData, (x) => x.month);

  let monthly = _.map(groupedByMonth, (value, key) => {
    const currentYearRevenues = _.sumBy(
      _.filter(value, (x) => x.year === currentYear),
      (x) => x.value
    );
    const previousYearRevenues = _.sumBy(
      _.filter(value, (x) => x.year === previousYear && x.month <= currentMonth),
      (x) => x.value
    );
    const previousYearRevenuesOver = _.sumBy(
      _.filter(value, (x) => x.year === previousYear && x.month > currentMonth),
      (x) => x.value
    );
    return {
      Mese: parseInt(key),
      AnnoInCorso: currentYear,
      AnnoPrecedente: previousYear,
      FatturatoAnnoPrecedente: previousYearRevenues,
      FatturatoAnnoPrecedenteOltrePeriodo: previousYearRevenuesOver,
      FatturatoAnnoInCorso: currentYearRevenues,
    };
  });

  // add missing months and order
  _.forEach(_.range(1, 13), (month) => {
    if (!_.find(monthly, (x) => x.Mese === month)) {
      monthly.push(getEmptyMonth(month, currentYear, previousYear));
    }
  });
  monthly = _.orderBy(monthly, (x) => x.Mese);

  return {
    currentYear,
    previousYear,
    base: [base],
    monthly,
    yearly,
  };
};

const getEmptyMonth = (month: Number, currentYear: Number, previousYear: Number) => {
  return {
    Mese: month,
    AnnoInCorso: currentYear,
    AnnoPrecedente: previousYear,
    FatturatoAnnoPrecedente: 0,
    FatturatoAnnoPrecedenteOltrePeriodo: 0,
    FatturatoAnnoInCorso: 0,
  };
};

const filterYears = (years, groupedByYear): void => {
  // Fill data structure with missing years
  const currentYear = new Date().getFullYear();
  if (years.length === 0) {
    years.push(currentYear);
    years.push(currentYear - 1);
    groupedByYear[currentYear] = [];
    groupedByYear[currentYear - 1] = [];
  } else if (years.length === 1) {
    let missingYear = years[0] - 1;
    if (years[0] !== currentYear) {
      missingYear = years[0] + 1;
    }
    years.push(missingYear);
    groupedByYear[missingYear] = [];
  }
};
