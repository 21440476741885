import './payments-due-dates.css';
import React, { useEffect, useState } from 'react';
import { getMetamodel } from '../../../metamodels/top-10-customers';

export function Top10Customers(props) {
  const { data } = props;
  const [isLoading, setIsLoading] = useState(true);

  const elementId = 'analyticsView-top-10-customers';
  const kpiName = 'ScadenziarioAttivoClientiTop10Scaduto';

  const widgetOptions = {
    embedFonts: false,
    showContainer: true,
    showFooter: false,
    showTitle: true,
  };

  const metamodelOptions = {
    elementId,
    kpiName,
  };

  useEffect(() => {
    if (!data) {
      setIsLoading(true);
    } else if (data.error) {
      setIsLoading(false);
      window.analyticsRender(metamodelOptions, widgetOptions);
    } else {
      setIsLoading(false);
      window.analyticsRender(getMetamodel(data, metamodelOptions), widgetOptions);
    }
  }, [data]);

  return <div class="top-10-customers-widget">
    {isLoading ?
      <div>
      </div> : <>
        <div id={elementId} class="top-10-customers-widget">
        </div>
      </>
    }
  </div>
}
